import React from 'react';
import { ReactComponent as LockIcon } from '../../../src/svgs/icons/lock.svg';
import {
	ReactComponent as HostJaneIcon,
	ReactComponent as FlameIcon,
} from '../../../src/svgs/nav/HostJane.svg';
import { ReactComponent as ContactSupportIcon } from '../../../src/svgs/icons/contact-support.svg';
import { ReactComponent as SellerGuideLinesIcon } from '../../../src/svgs/icons/seller-guidelines.svg';
import { ReactComponent as Gmail } from '../../../src/svgs/icons/gmail.svg';
import { ReactComponent as PaymentsIcon } from '../../../src/svgs/icons/payments.svg';
import { ReactComponent as PopularAnswersIcon } from '../../../src/svgs/icons/popular-answers.svg';
import { ReactComponent as WebHostPanelIcon } from '../../home/hero/webhostpanel.svg';
import { ReactComponent as FreelanceLinkIcon } from '../../home/hero/freelancerlink.svg';
import { ReactComponent as ProductsMenu } from '../../../src/svgs/icons/menu.svg';
import { ReactComponent as Messenger } from '../../../src/svgs/icons/messenger-desktop.svg';
import { ReactComponent as Telegram } from '../../../src/svgs/icons/telegram-desktop.svg';
import { ReactComponent as TelegramChannel } from '../../../src/svgs/icons/telegram-app.svg';
import { ReactComponent as CheapHostingIcon } from '../../../src/svgs/homenav/cheap-hosting.svg';
import { ReactComponent as CommunityIcon } from '../../../src/svgs/nav/get-started.svg';
import { ReactComponent as MissionIcon } from './mission.svg';

export default {
	topNav: {
		sellTeach: {
			title: 'Become a Seller',
			heading: 'Sell a FREE listing or booking',
			subheading: 'Payouts to PayPal, Venmo, VISA® card, or bank.',
			link: '/sell',
			links: [
				[
					{
						title: 'Start a listing',
						link: '/sell',
						icon: HostJaneIcon,
						className: 'hostjane-main',
					},
					{
						title: 'See the math',
						link: 'https://help.hostjane.com/sellers/earnings/',
						icon: PaymentsIcon,
					},
				],
				[
					{
						title: 'How it works',
						link:
							'https://help.hostjane.com/sellers/getting-orders/',
						icon: SellerGuideLinesIcon,
					},
					{
						title: 'Questions',
						link: 'https://help.hostjane.com/sellers/',
						icon: ContactSupportIcon,
					},
				],
			],
			popularAnswers: {
				title: 'We pay 95% of each sale',
				link: 'https://help.hostjane.com/about/',
				icon: PopularAnswersIcon,
				links: [
					{
						title: 'We give you a better workspace',
						link: 'https://help.hostjane.com/marketplace-faq/what-are-boards/',
					},
					{
						title: 'We protect you from fraud',
						link: 'https://help.hostjane.com/marketplace-faq/hostjane-roadmap/',
					},
					{
						title: 'Explain licensing to me',
						link: 'https://help.hostjane.com/how-licensing-works/',
					},
				],
			},
		},
		navs: [
			{
				title: <span>Contact</span>,
				highlighted: true,
				icon: ProductsMenu,
				noDecoration: true,
				children: [
					{
						title: '24/7 Hosting Support',
						description: 'OPEN TICKET - 10 MIN WAIT',
						href: '/marketplace/support-ticket',
						icon: Gmail,
					},
					{
						title: 'Telegram',
						description: '24/7 SECURE LIVE CHATS',
						href: 'https://t.me/hostjanesupport',
						icon: Telegram,
					},
					{
						title: 'Messenger',
						description: 'TEXT US ON FACEBOOK',
						href: 'https://m.me/hostjanecom',
						icon: Messenger,
					},
					{
						title: 'Telegram Channel',
						description: 'LATEST NEWS + UPDATES',
						href: 'https://t.me/hostjanecom',
						icon: TelegramChannel,
					},
					{
						title: 'Sellers, Join Free',
						description: 'ZERO FEES. 5 DAY PAYOUT.',
						href: '/sell',
						icon: FreelanceLinkIcon,
						borderTop: true,
						internal: true,
					},
					{
						title: 'Curious Cat?',
						description: 'ALL ABOUT JANE AND HER MISSION',
						href: '/about',
						icon: CommunityIcon,
						internal: true,
					},
					{
						title:
							'<a href="https://www.hostjane.com/marketplace/categories" target="_blank">Hire a freelancer</a> in 1735 Skills',
						description:
					     	'Contact our <a href="/marketplace/contact" target="_blank">Marketplace Team</a>',
						borderTop: true,
						bottomChild: true,
					},
				],
			},
			{
				title: 'Sign Up',
				link: 'https://www.hostjane.com/marketplace/register',
				addHoverBackground: true,
			},
			{
				title: (
					<span>
						Sign In
					</span>
				),
				popupTitle: 'Sign In To',
				highlighted: true,
				icon: LockIcon,
				children: [
					{
						title: 'Marketplace',
						href: 'https://www.hostjane.com/marketplace/login',
						icon: FreelanceLinkIcon,
					},
					{
						title: 'Hosting',
						href: 'https://cloud.hostjane.com/hosting/login',
						icon: WebHostPanelIcon,
						internal: false,
					},
				],
			},
		],
		skills: {
			title: 'Skills',
			link: 'https://www.hostjane.com/marketplace/skills',
		},
	},
	// search categories
	searchCats: [
		{
			title: 'All',
			value: 'all',
		},
		{
			title: 'Web / Mobile / Tech',
			value: 'web-mobile-tech-freelancers',
		},
		{
			title: 'Design / Art / Video / Audio',
			value: 'design-art-video-voice',
		},
		{
			title: 'Bookings / Tutors',
			value: 'online-tutors',
		},
		{
			title: 'Writing / Translation',
			value: 'writing-translation-freelancers',
		},
		{
			title: 'Business / Admin',
			value: 'business-admin-support',
		},
	],
	// First Buy Hosting, highlighted
	highlighted: {
		title: 'Web Hosting',
		link: 'https://cloud.hostjane.com',
		navs: [
			{
				items: [
					{
						title: 'Fast, Managed VPS',
						subtitle: 'AWS Virtual Private Servers',
						href: 'https://cloud.hostjane.com/vps-hosting/',
						type: 'big',
						internal: true,
					},
					{
						title: 'WordPress Hosting',
						subtitle: 'Managed WordPress on AWS',
						href: 'https://cloud.hostjane.com/wordpress/',
						type: 'big',
						internal: true,
					},
					{
						title: 'Cloud Hosting',
						subtitle: 'Managed AWS EC2 Servers',
						href: 'https://cloud.hostjane.com/cloud/',
						type: 'big',
						internal: true,
					},
				],
			},
			{
				items: [
					{
						title: 'Windows Servers',
						subtitle: 'Managed Windows on AWS',
						href: 'https://cloud.hostjane.com/windows/',
						type: 'big',
						internal: true,
					},
					{
						title: 'Server FAQs',
						subtitle: 'Fast, clear answers',
						href: 'https://cloud.hostjane.com/vps-hosting/#hostjane-faqs',
						type: 'big',
						internal: true,
					},
					{
						title: 'Open a Ticket',
						subtitle: 'Get support 24/7, 365 days',
						href: '/marketplace/support-ticket',
						type: 'big',
						internal: true,
					},
				],
			},
			{
				items: [
					{
						title: 'Billing Help',
						subtitle: '10 min wait times',
						href: '/marketplace/support-ticket',
						type: 'big',
						internal: true,
					},
					{
						title: 'Website Transfer',
						subtitle: 'Let us move your site',
						href: 'https://cloud.hostjane.com/hosting/store/addons/website-transfer-service',
						type: 'big',
					},
					{
						title: 'Customer Portal',
						href:
							'https://cloud.hostjane.com/hosting/',
						type: 'button',
						internal: false,
					},
				],
			},
			{
				items: [
					{
						title: 'Point your domain to hosting',
						subtitle: 'How to update your A Record',
						href:
							'https://help.hostjane.com/point-your-domain',
						type: 'image',
						imageUrl:
							'https://www.hostjane.com/assets/theme-plugin-reviews.svg',
					},
				],
			},
		],
	},
	// Then others
	others: [
		{
			title: 'Web / Mobile / Tech',
			link:
				'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers',
			navs: [
				{
					title: 'Highest demand',
					items: [
						{
							title: 'Web Development',
							subtitle: 'Build, code and maintain websites',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development',
							type: 'full',
						},
						{
							title: 'Web Design / Apps',
							subtitle: 'Design your website or app',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/web-design-apps',
							type: 'full',
						},
						{
							title: 'WordPress',
							subtitle: 'Develop WP themes & plugins',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/wordpress',
							type: 'full',
						},
						{
							title: 'Blockchain / Crypto / NFTs',
							subtitle: 'Freelancers for your blockchain, crypto, NFT',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/blockchain-cryptocurrency-nfts',
							type: 'full',
						},
						{
							title: 'Apps / Mobile',
							subtitle: 'Design secure mobile applications',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/apps-mobile',
							type: 'full',
						},
						{
							title: 'Math / Science Help',
							subtitle: 'Scientists for hire',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms',
							type: 'full',
						},
						{
							title: 'Programming / Software',
							subtitle: 'Plan, write and design source code',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software',
							type: 'full',
						},
					],
				},
				{
					title: 'Featured services',
					items: [
						{
							title: 'Bug Fixing Services',
							subtitle: 'Debug & resolve issues',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services',
							type: 'full',
						},
						{
							title: 'Game Development',
							subtitle: 'Build, test, design games',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development',
							type: 'full',
						},
						{
							title: 'Ideas / Help / Consultation',
							subtitle: 'Consult with IT experts',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation',
							type: 'full',
						},
						{
							title: 'Database Design / Admin',
							subtitle: 'Organize & secure data storage',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration',
							type: 'full',
						},
						{
							title: 'QA / Testing',
							subtitle: 'Troubleshoot and test systems',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing',
							type: 'full',
						},
						{
							title: 'Networking / System Admin',
							subtitle: 'Install and maintain systems incl. AWS, Linux and Cloud',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin',
							type: 'full',
						},
						{
							title: 'Cybersecurity / InfoSec',
							subtitle: 'Information security to protect sensitive data',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security',
							type: 'full',
						},
					],
				},
				{
					title: 'Key services',
					items: [
						{
							title: 'ERP / CRM / SCM',
							subtitle: 'Streamline your business',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm',
							type: 'full',
						},
						{
							title: 'Management / Training',
							subtitle: 'Allocate resources properly',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/management-training',
							type: 'full',
						},
						{
							title: 'Telephony / Telecommunications',
							subtitle: 'Voice and sound technicians',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications',
							type: 'full',
						},
						{
							title: 'SAP',
							subtitle: 'Find SAP experts',
							href:
								'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap',
							type: 'full',
						},
					],
				},
			],
		},
		{
			title: 'Design / Art / Video / Audio',
			link:
				'https://www.hostjane.com/marketplace/categories/design-art-video-voice',
			navs: [
				{
					title: 'Highest demand',
					items: [
						{
							title: 'Graphic Design / Logos',
							subtitle: 'Expert designers for less',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos',
							type: 'full',
						},
						{
							title: 'Freelance Artists',
							subtitle: 'Hire creative talent',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art',
							type: 'full',
						},
						{
							title: 'Video / Animation',
							subtitle: 'Make amazing productions',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation',
							type: 'full',
						},
						{
							title: 'Illustration',
							subtitle: 'Skilled artists on-demand',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration',
							type: 'full',
						},
						{
							title: 'Voice Over / Acting',
							subtitle: 'Find the perfect voice',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting',
							type: 'full',
						},
						{
							title: 'Musicians / Singers',
							subtitle: 'Ready-to-record talent',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers',
							type: 'full',
						},
						{
							title: 'Book / Magazine Design',
							subtitle: 'Create eye-catching designs',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/book-magazine-design',
							type: 'full',
						},
					],
				},
				{
					title: 'Featured services',
					items: [
						{
							title: 'Audio / Sound / Music',
							subtitle: 'From mixing to audio editing',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music',
							type: 'full',
						},
						{
							title: 'File Conversions',
							subtitle: 'Manage all file types',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions',
							type: 'full',
						},
						{
							title: 'Business / Advertising Design',
							subtitle: 'Empower your brand',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design',
							type: 'full',
						},
						{
							title: 'T-Shirts / Merchandise Design',
							subtitle: 'Create your clothing brand',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/t-shirts-merchandise-design',
							type: 'full',
						},
						{
							title: 'Packaging / Label Design',
							subtitle: 'Hire packaging designers',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/packaging-label-design',
							type: 'full',
						},
						{
							title: 'CAD / Technical Drawings',
							subtitle: 'Drafting / modeling experts',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings',
							type: 'full',
						},
						{
							title: 'Image Restoration / Editing',
							subtitle: 'Hire photoshop gurus',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing',
							type: 'full',
						},
					],
				},
				{
					title: 'Creative essentials',
					items: [
						{
							title: 'Christian Services',
							subtitle: 'Celebrate God\'s Word',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/christian-services',
							type: 'full',
						},
						{
							title: 'Photography',
							subtitle: 'Freelance photographers',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/photography',
							type: 'full',
						},
						{
							title: 'Printing / Production',
							subtitle: 'Prepress to desktop publishing',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production',
							type: 'full',
						},
						{
							title: 'Painting',
							subtitle: 'Watercolor to digital painting',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting',
							type: 'full',
						},
						{
							title: 'Cartoons / Comic Art',
							subtitle: 'Talented cartoonists for hire',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cartoons-comic-art',
							type: 'full',
						},
						{
							title: 'Fashion',
							subtitle: 'From textiles to jewelry design',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/fashion',
							type: 'full',
						},
						{
							title: 'Concepts / Direction',
							subtitle: 'Art direction on-demand',
							href:
								'https://www.hostjane.com/marketplace/categories/design-art-video-voice/concepts-direction',
							type: 'full',
						},
					],
				},
			],
		},
		{
			title: 'Bookings / Tutors',
			link:
				'https://www.hostjane.com/marketplace/categories/online-tutors',
			navs: [
				{
					title: 'Highest demand',
					items: [
						{
							title: 'Language Tutors',
							subtitle: 'Learn a new language!',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors',
							type: 'full',
						},
						{
							title: 'Live Chats',
							subtitle: 'Online therapy services',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/live-chats',
							type: 'full',
						},
						{
							title: 'Online Experiences',
							subtitle: 'D.I.Y. advice to cooking',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons',
							type: 'full',
						},
						{
							title: 'ASMR / Therapy',
							subtitle: 'The best version of yourself',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development',
							type: 'full',
						},
						{
							title: 'Test Prep Tutors',
							subtitle: 'Improve your grades',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/test-prep-tutors',
							type: 'full',
						},
						{
							title: 'IT / Computing',
							subtitle: 'Learn how to code',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing',
							type: 'full',
						},
						{
							title: 'Math Lessons',
							subtitle: 'Hire math tutors',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons',
							type: 'full',
						},
					],
				},
				{
					title: 'Featured services',
					items: [
						{
							title: 'Investing & Trading Lessons',
							subtitle: 'Learn how to trade',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/investing-trading-lessons',
							type: 'full',
						},
						{
							title: 'Music Lessons',
							subtitle: 'Learn a new instrument',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons',
							type: 'full',
						},
						{
							title: 'Finance / Accounting Lessons',
							subtitle: 'Learn accounting & tax prep',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/finance-accounting-lessons',
							type: 'full',
						},
						{
							title: 'Online Interpreting Services',
							subtitle: 'Hire remote interpreters',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/online-interpreting-services',
							type: 'full',
						},
						{
							title: 'Business Training',
							subtitle: 'Teach your staff skills',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/business-training',
							type: 'full',
						},
						{
							title: 'Science Lessons',
							subtitle: 'Master science concepts',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/science-lessons',
							type: 'full',
						},
						{
							title: 'English Lessons',
							subtitle: 'Improve your English',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/english-lessons',
							type: 'full',
						},
					],
				},
				{
					title: 'Other bookings',
					items: [
						{
							title: 'Creative / Design Lessons',
							subtitle: 'Hire art teachers',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/creative-design-lessons',
							type: 'full',
						},
						{
							title: 'Teacher Training',
							subtitle: 'Become a better teacher',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/teacher-training',
							type: 'full',
						},
						{
							title: 'History Lessons',
							subtitle: 'Learn how to research',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons',
							type: 'full',
						},
						{
							title: 'Theology Classes',
							subtitle: 'Bible study and more',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/theology',
							type: 'full',
						},
						{
							title: 'Health / Fitness Teachers',
							subtitle: 'Learn with qualified trainers',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers',
							type: 'full',
						},
						{
							title: 'Office Productivity',
							subtitle: 'Learn Microsoft & Apple products',
							href:
								'https://www.hostjane.com/marketplace/categories/online-tutors/office-productivity',
							type: 'full',
						},
					],
				},
			],
		},
		{
			title: 'Writing / Translation',
			link:
				'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers',
			navs: [
				{
					title: 'Highest demand',
					items: [
						{
							title: 'Translation',
							subtitle: 'Expert human translators',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation',
							type: 'full',
						},
						{
							title: 'Creative Writing',
							subtitle: 'Hire talented wordsmiths',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing',
							type: 'full',
						},
						{
							title: 'Book Writing',
							subtitle: 'Make your book shine',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing',
							type: 'full',
						},
						{
							title: 'General Writing',
							subtitle: 'Experienced writers for hire',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing',
							type: 'full',
						},
						{
							title: 'Copywriting',
							subtitle: 'Devise copy that sells',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/copywriting',
							type: 'full',
						},
						{
							title: 'Editing / Proofreading',
							subtitle: 'Hire the right editor',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading',
							type: 'full',
						},
						{
							title: 'Web Content',
							subtitle: 'Hire specialist online writers',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/web-content',
							type: 'full',
						},
					],
				},
				{
					title: 'Featured services',
					items: [
						{
							title: 'Article / News Writing',
							subtitle: 'Hire on-demand journalists',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing',
							type: 'full',
						},
						{
							title: 'Subtitling',
							subtitle: 'Hire skilled subtitlers',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/subtitling',
							type: 'full',
						},
						{
							title: 'Research',
							subtitle: 'Hire professional researchers',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/research',
							type: 'full',
						},
						{
							title: 'Writing for Industries',
							subtitle: 'Niche writers on-demand',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries',
							type: 'full',
						},
						{
							title: 'Scripts / Speeches / Storyboards',
							subtitle: 'Hire drama & genre writers',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/scripts-speeches-storyboards',
							type: 'full',
						},
						{
							title: 'Jobs / Resumes',
							subtitle: 'Improve your CV',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/jobs-resumes',
							type: 'full',
						},
						{
							title: 'Review Writing',
							subtitle: 'Write critiques / commentary',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/review-writing',
							type: 'full',
						},
					],
				},
				{
					title: 'Creative essentials',
					items: [
						{
							title: 'Christian Worship',
							subtitle: 'Faith-based writing',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/christian-worship',
							type: 'full',
						},
						{
							title: 'Grants / Proposals',
							subtitle: 'Strengthen your proposals',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/grants-proposals',
							type: 'full',
						},
						{
							title: 'Songs / Poems',
							subtitle: 'Creative poets on-demand',
							href:
								'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/songs-poems',
							type: 'full',
						},
					],
				},
			],
		},
		{
			title: 'Business',
			link:
				'https://www.hostjane.com/marketplace/categories/business-admin-support',
			navs: [
				{
					title: 'Highest demand',
					items: [
						{
							title: 'Digital Marketing',
							subtitle: 'Reach more eyeballs',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing',
							type: 'full',
						},
						{
							title: 'Accounting / Finance / Tax',
							subtitle: 'Make tax season easy',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax',
							type: 'full',
						},
						{
							title: 'Business Help / Consulting',
							subtitle: 'Get a fresh pair of eyes',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-help-consulting',
							type: 'full',
						},
						{
							title: 'Legal Assistance',
							subtitle: 'Hire freelance lawyers',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance',
							type: 'full',
						},
						{
							title: 'Personal / Virtual Assistants',
							subtitle: 'Hire a cloud workforce',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants',
							type: 'full',
						},
						{
							title: 'Transcription Services',
							subtitle: 'Turn speech into printed words',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/transcription-services',
							type: 'full',
						},
						{
							title: 'Sales / General Marketing',
							subtitle: 'Hone your brand message',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/sales-general-marketing',
							type: 'full',
						},
					],
				},
				{
					title: 'Featured services',
					items: [
						{
							title: 'Business Intelligence',
							subtitle: 'Monitor, collect + report data',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/business-intelligence',
							type: 'full',
						},
						{
							title: 'Telemarketing / Remote Selling',
							subtitle: 'Power your business goals',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/call-centers-telemarketing',
							type: 'full',
						},
						{
							title: 'Press Release Writing',
							subtitle: 'Hire marketing gurus',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/press-release-writing',
							type: 'full',
						},
						{
							title: 'Data Entry / Micro Jobs',
							subtitle: 'Accurate update & input data',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry',
							type: 'full',
						},
						{
							title: 'Spreadsheets / Excel / Office',
							subtitle: 'Hire MS 365 experts',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office',
							type: 'full',
						},
						{
							title: 'Customer Service',
							subtitle: 'Hire phone to email support',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/customer-service',
							type: 'full',
						},
						{
							title: 'Word Processing / Typing',
							subtitle: 'Get help with business docs',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/word-processing-typing',
							type: 'full',
						},
					],
				},
				{
					title: 'Core Essentials',
					items: [
						{
							title: 'Mailings / Lists',
							subtitle: 'Ethical email marketing',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/mailings-lists',
							type: 'full',
						},
						{
							title: 'Human Resources (HR)',
							subtitle: 'Help recruiting & managing staff',
							href:
								'https://www.hostjane.com/marketplace/categories/business-admin-support/human-resources-hr',
							type: 'full',
						},
					],
				},
			],
		},
		{
			title: 'Help',
			link: '#',
			navs: [
				{
					title: '',
					items: [
						{
							title: 'Help Center',
							subtitle: 'How can we help you?',
							href: 'https://help.hostjane.com',
							type: 'full',
						},
						{
							title: 'Hosting Support',
							subtitle: 'Open a new ticket - 10 min wait',
							href:
								'/marketplace/support-ticket',
							type: 'full',
						},
						{
							title: 'Community',
							subtitle: 'Connect with freelancers',
							href:
								'/community',
							type: 'full',
						},
						{
							title: 'Move My Site',
							subtitle: 'Fast website transfers',
							href:
								'https://cloud.hostjane.com/hosting/store/addons/website-transfer-service',
							type: 'full',
						},
						{
							title: 'Contact HostJane',
							subtitle: '',
							href:
								'https://www.hostjane.com/marketplace/contact',
							type: 'button',
						},
					],
				},
			],
		},
	],
};
